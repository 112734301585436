<template>
    <div class="question-item">
        <h2 :required="required" :style="{ color: pageInfo.style.questionColor }">
            {{ title }}
        </h2>
        <div>
            <Input long />
        </div>
    </div>
</template>
<script>
export default {
    name: "Mobile",
    props: ["title", "required", "pageInfo"],
};
</script>